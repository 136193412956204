import React from 'react';
import  Loader  from "react-js-loader";
import './App.css';

function App(props) {
  return (
    <div>
      <div className="App">
          <div style={{ width: '100%', height: '100%' }}>
              <Loader type="spinner-default"  bgColor={"#BBB"} title={"Please Wait..."} color={'#BBB'} size={100} />
          </div>
      </div>
    </div>
  )
}

export default App;
